<template>
  <div>
    <v-card id="card-modal-assign-vehicle" class="elevation-4 ">
      <v-card-title class="justify-start py-2 card-modaltitle">
        <div class="pl-4">
          <h3 class="primary--text title--text">
            {{ $t("components.views.trip.no_offer_assign.modal_assign.assign") }}
          </h3>
        </div>
        <v-btn depressed text color="primary" class="cont" @click.stop="$emit('closeForm')">
          <v-icon class="mr-1">fa-solid fa-xmark</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text >
        <v-form v-model="valida" dense>
          <v-container fluid class="pl-2">
            <v-row class="justify-center">
              <v-col cols="12" class="pb-0">
                <SelectAutocomplete
                  v-model="company"
                  url="/TransportCompany"
                  item-text="businessName"
                  item-value="businessName"
                  dense
                  return-object
                  prepend-icon="fa-solid fa-building"
                  required
                  hide-details
                  :label="
                    $t('components.views.trip.no_offer_assign.modal_assign.business_transport')
                  "
                  :placeholder="
                    $t('components.views.trip.no_offer_assign.modal_assign.business_transport')
                  "
                  :rules="[
                    required(
                      $t('components.views.trip.no_offer_assign.modal_assign.business_transport'),
                      1
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="12" class="pb-0">
                <SelectAutocomplete
                  ref="driver"
                  v-model="driver"
                  url="/Drivers"
                  dense
                  return-object
                  item-text="identification"
                  item-value="identification"
                  required
                  prepend-icon="fa-solid fa-id-card"
                  :label="
                    $t('components.views.trip.no_offer_assign.modal_assign.identification_number')
                  "
                  :placeholder="
                    $t('components.views.trip.no_offer_assign.modal_assign.identification_number')
                  "
                  :rules="[
                    required(
                      $t('components.views.trip.no_offer_assign.modal_assign.identification_number')
                    ),
                  ]"
                  hide-details
                  maxlength="15"
                  :search-input.sync="driverCreate"
                  @click:clear="cleanFieldDriver()"
                >
                  <template slot="no-data">
                    <v-list-item @click="loadModalNewDriver()">
                      <v-list-item-title>{{
                        $t("components.views.trip.no_offer_assign.modal_assign.create_new_driver")
                      }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </SelectAutocomplete>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="12" class="pt-2 pb-0">
                <v-text-field
                  v-model="driverNameComputed"
                  required
                  outlined
                  dense
                  readonly
                  hide-details
                  :label="$t('components.views.trip.no_offer_assign.modal_assign.names_last_names')"
                  :placeholder="
                    $t('components.views.trip.no_offer_assign.modal_assign.names_last_names')
                  "
                  prepend-icon="fa-solid fa-user"
                />
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="10" class="pb-0">
                <SelectAutocomplete
                  ref="vehicle"
                  v-model="vehicle"
                  :url="urlVehicle"
                  return-object
                  dense
                  item-text="licensePlate"
                  item-value="licensePlate"
                  maxlength="10"
                  :search-input.sync="vehicleCreate"
                  prepend-icon="fa-solid fa-truck-moving"
                  :label="$t('components.views.trip.no_offer_assign.modal_assign.license_plate')"
                  :selected="dataProp.licensePlate"
                  required
                  :placeholder="
                    $t('components.views.trip.no_offer_assign.modal_assign.license_plate')
                  "
                  :rules="[
                    required(
                      $t('components.views.trip.no_offer_assign.modal_assign.license_plate'),
                      1
                    ),
                  ]"
                  hide-details
                  @change="infoGeneral()"
                >
                  <template slot="no-data">
                    <v-list-item @click="loadModalNewVehicle()">
                      <v-list-item-title>{{
                        $t("components.views.trip.no_offer_assign.modal_assign.create_new_vehicle")
                      }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </SelectAutocomplete>
              </v-col>
              <v-col cols="2" class="pt-7 pb-0">
                <v-tooltip bottom color="primary" v-if="userGPS !== undefined">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      id="stateGPSColor"
                      v-bind="attrs"
                      :style="{
                        backgroundColor: backgroundColorStateGps,
                        color: colorFontStateGps,
                      }"
                      style="background-color: gray; color: #fff"
                      v-on="on"
                    >
                      GPS
                    </div>
                  </template>
                  <div id="info_gps">
                    <b
                      >{{ $t("components.views.trip.no_offer_assign.modal_assign.user_gps") }} :
                    </b>
                    <span>{{ userGPS }}</span>
                    <br />
                    <b
                      >{{ $t("components.views.trip.no_offer_assign.modal_assign.date_gps") }} :
                    </b>
                    <span>{{ dateLastGPS }}</span>
                  </div>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="12" class="pt-2 pb-0">
                <v-text-field
                  v-model="typology"
                  outlined
                  readonly
                  dense
                  hide-details
                  :label="$t('components.views.trip.no_offer_assign.modal_assign.type_vehicle')"
                  :placeholder="
                    $t('components.views.trip.no_offer_assign.modal_assign.type_vehicle')
                  "
                  prepend-icon="fa-solid fa-bullseye"
                />
              </v-col>
            </v-row>
            <v-row class="contaaner__btn pr-2">
              <v-col cols="6" class="pa-3" >
                <v-btn color="secondary"  style="height: 35px;" :disabled="!valida" @click="assign()" depressed block>
                  <v-icon class="mr-2" small>fa-solid fa-check</v-icon>
                  {{ $t("components.views.trip.no_offer_assign.modal_assign.assign") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogDriver" scrollable width="35%" persistent>
      <ModalCreateDriver
        :idDriver="driverCapture"
        :idCompany="idCompany"
        @closeForm="onCloseFormDriver()"
        @driverCreated="onDriverCreated"
      />
    </v-dialog>

    <v-dialog v-model="dialogVehicle" scrollable width="20%" persistent>
      <ModalCreateVehicle
        :idLicensePlate="vehicleCapture"
        :idCompany="idCompany"
        :key="index"
        @closeForm="onCloseFormVehicle()"
        @vehicleCreated="onVehicleCreated"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import validate from "@/plugins/validate";
export default {
  components: {
    ModalCreateDriver: () =>
      import(
        /* webpackChunkName: "ModalCreateDriver" */ "@/components/views/Trip/NoOfferAssign/ModalCreateDriver.vue"
      ),
    ModalCreateVehicle: () =>
      import(
        /* webpackChunkName: "ModalCreateVehicle" */ "@/components/views/Trip/NoOfferAssign/ModalCreateVehicle.vue"
      ),
  },
  props: {
    idMonitoringOrder: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      index: 1,
      ...validate,
      dialogDriver: false,
      dialogVehicle: false,
      valida: false,
      typology: "",
      vehicle: {},
      gps: {},
      company: {},
      driverName: "",
      driverCreate: null,
      driverCapture: "",
      vehicleCreate: null,
      vehicleCapture: "",
      driver: {},
    };
  },
  computed: {
    dataProp: {
      get() {
        return this.data
          ? this.data
          : {
              id: "",
              idVehicle: "",
              licensePlate: "",
            };
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    backgroundColorStateGps() {
      return this.gps.stateGPS == 0
        ? "green"
        : this.gps.stateGPS == 1
        ? "yellow"
        : this.gps.stateGPS == 2
        ? "silver"
        : "silver";
    },
    ...mapGetters({
      user: "auth/user",
    }),
    idCompany() {
      let userMap = JSON.parse(this.user);
      return userMap.user.idCompany;
    },
    urlVehicle() {
      return `/Vehicles/${this.idMonitoringOrder}`;
    },
    colorFontStateGps() {
      return this.gps.stateGPS == 0
        ? "#FFFFFF"
        : this.gps.stateGPS == 1
        ? "#000000"
        : this.gps.stateGPS == 2
        ? "#FFFFFF"
        : "#FFFFFF";
    },
    userGPS() {
      return this.gps.username !== "" ? this.gps.username : this.dataProp.user_gps;
    },
    dateLastGPS() {
      return this.gps.dateGPS !== ""
        ? this.moment(new Date(this.gps.dateGPS)).format("DD-MM-YYYY hh:mm:ss")
        : this.dataProp.date_gps;
    },
    driverNameComputed() {
      return this.driverName !== "" ? this.driverName : this.dataProp.driver_name;
    },
  },
  watch: {
    driver(newVal) {
      if (newVal) {
        this.driverName = this.driver.name + " " + this.driver.lastName;
      }
    },
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions("loader", ["showLoader"]),

    async assign() {
      this.showLoader({
        show: true,
      });

      this.valida = false;

      let params = {
        idMonitoringOrder: this.idMonitoringOrder,
        idVehicle: this.vehicle.idVehicle,
        idDriver: this.driver.idDriver,
        idCompany: this.idCompany,
        idTransporter: this.company.idCompany,
      };

      await this.axios
        .post(`Travels/AssignTransportCompaniesAndDriversAndVehicles`, params)
        .then(() => {
          this.showSnack({
            text: this.$t(
              "components.views.trip.no_offer_assign.modal_assign.assignment_successful"
            ),
            title: this.$t("components.views.trip.no_offer_assign.modal_assign.success"),
            name: "success",
          });
        })
        .catch((error) => {
          this.showSnack({
            text: error.response.data,
            title: this.$t(
              "components.views.trip.no_offer_assign.modal_assign.assignment_not_possible"
            ),
            name: "error",
          });
        })
        .finally(() => {
          this.valida = true;
          this.$emit("reloadPage");
          this.showLoader({
            show: false,
          });
        });
    },

    async showTypeVehicle() {
      let idVehicleTypology =
        this.vehicle.idVehicleTypology == null ? "0" : this.vehicle.idVehicleTypology;
      await this.axios.get(`/Vehicles/typology/${idVehicleTypology}`).then((result) => {
        this.typology = result.data;
      });
    },

    async showGpsVehicle() {
      let licensePlate = this.vehicle.licensePlate == null ? "0" : this.vehicle.licensePlate;
      await this.axios.get(`/Vehicles/gps/${licensePlate}`).then((result) => {
        this.gps = result.data;
      });
    },

    onOpenFormDriver() {
      this.dialogDriver = true;
    },

    onCloseFormDriver() {
      this.dialogDriver = false;
    },

    cleanFieldDriver() {
      this.driverName = "";
      this.dataProp.driver_name = "";
    },

    async onDriverCreated(data) {
      if (data) {
        this.dataProp.identification_driver = data;
        await this.$refs.driver.loadData();
      }
    },

    loadModalNewDriver() {
      let driver = this.driverCreate;
      this.driverCapture = driver;
      this.onOpenFormDriver();
    },

    async infoGeneral() {
      this.showTypeVehicle();
      this.showGpsVehicle();
    },

    onOpenFormVehicle() {
      this.dialogVehicle = true;
    },

    onCloseFormVehicle() {
      this.dialogVehicle = false;
    },

    async onVehicleCreated(data) {
      if (data) {
        await this.$refs.vehicle.loadData();
      }
    },

    loadModalNewVehicle() {
      this.index++;
      let vehicle = this.vehicleCreate;
      this.vehicleCapture = vehicle;
      this.onOpenFormVehicle();
    },
  },
};
</script>

<style scoped>
.title--text {
  font-size: 20px;
}

#card-modal-assign-vehicle {
  border: 3px solid #0b0045 !important;
  height: 430px;
}

.v-divider {
  border-width: 1px !important;
}

#card-modal-assign-vehicle p {
  font-size: 1.17em !important;
}

.cont {
  position: absolute;
  right: 10px;
}

#stateGPSColor {
  display: block;
  width: 100%;
  text-align: center;
  height: 23px;
  padding-top: 1px;
  border-radius: 10px;
}

#info_gps {
  font-size: 0.9em;
}

.card-modaltitle {
  border-bottom: 1px solid #ccc;
}

.contaaner__btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


</style>
