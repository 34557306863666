import { render, staticRenderFns } from "./ModalAssign.vue?vue&type=template&id=27ede579&scoped=true&"
import script from "./ModalAssign.vue?vue&type=script&lang=js&"
export * from "./ModalAssign.vue?vue&type=script&lang=js&"
import style0 from "./ModalAssign.vue?vue&type=style&index=0&id=27ede579&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27ede579",
  null
  
)

export default component.exports